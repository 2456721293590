<template>
    <el-row :gutter="6" style="width: 750px; padding-top: 20px;">
        <el-col :span="1"></el-col>
        <el-col :span="8">
            <el-input v-model="serialNumber" maxlength="12" placeholder="查询最近的100个接头" show-word-limit type="text" />
        </el-col>
        <el-col :span="4">
            <el-button type="primary" @click="fetchData">查询</el-button>
        </el-col>
    </el-row>
    <el-row :gutter="0" style="width: 750px">
        <el-col :span="1"></el-col>
        <el-col :span="23">
            <el-button type="primary" :icon="Edit" size="small" @click="open">添加黑名单接头</el-button>
        </el-col>

    </el-row>

    <el-row :gutter="0" style="width: 750px">
        <el-col :span="1"></el-col>
        <el-col :span="23">
            <el-table :data="filterTableData">
                <el-table-column v-for="column in columns" :key="column.prop" :prop="column.prop" :label="column.label"
                    :width="column.width" header-align="center" align="center" />
                <el-table-column>
                    <template #header>
                        <el-input v-model="search" size="small" placeholder="接头筛选" />
                    </template>
                    <template #default="scope">
                        <!-- <el-button size="small" @click="handleEdit(scope.$index, scope.row)">Edit</el-button> -->
                        <el-popconfirm title="确认删除?" @confirm="handleDelete(scope.$index, scope.row)">
                            <template #reference>
                                <el-button size="small" type="danger">Delete</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>
</template>

<script>
import { ref, computed } from 'vue';
import { Edit } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getBlackSn, addBlackSn, deleteBlackSn } from '../api/users';
export default {
    setup() {
        // 列配置，这里你可以根据需要动态设置或静态定义  
        const serialNumber = ref('');
        const search = ref('');
        const columns = ref([
            { prop: 'sn', label: 'Sn', width: '125' },
            { prop: 'ban_reason', label: '封号原因', width: '250' },
            { prop: 'ban_time', label: '封号时间', width: '180' },
        ]);   

        const tableData = ref([]);

        const filterTableData = computed(() =>
            tableData.value.filter(
                (data) =>
                    !search.value ||
                    data.sn.toLowerCase().includes(search.value.toLowerCase())
            )
        );

        const fetchData = async () => {
            const serialNumberValue = (serialNumber.value && serialNumber.value.length === 12) ? serialNumber.value : "all";
            try {
                // 加载列配置 ，表格数据
                getBlackSn(100,serialNumberValue)
                    .then(response => {
                        // 处理登录成功的响应     
                           
                        columns.value = response.data.columns;

                        if(response.data.isblack){
                            tableData.value = response.data.tableData;
                            ElMessage.success('查询成功！');
                        }           
                        else{
                            ElMessage.success('已查询,非黑名单用户!');
                        }
                        
                    })
                    .catch(error => {
                        // 处理登录失败的响应  
                        ElMessage.error('查询接头失败！');
                    });
            } catch (error) {
                ElMessage({ type: 'error', message: '查询接头失败，请稍后重试' });
            }
        };

        const handleDelete = async (index, row) => {
            try {
                const value = row.sn;
                await deleteBlackSn(value);
                ElMessage({ type: 'success', message: '已同步到CN/US服务器中', })
                tableData.value.splice(index, 1);
            } catch (error) {
                ElMessage({ type: 'warning', message: '删除失败，请重试', })
            }
        };

        const open = () => {
            ElMessageBox.prompt('提交接头到服务器中', '提交', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputPattern: /^.{12}$/,
                inputErrorMessage: '需满足12位有效字符',
            })
                .then(async ({ value }) => {
                    try {
                        const serialNumberValue = { sn: value };
                        await addBlackSn(serialNumberValue);
                        ElMessage({ type: 'success', message: '已同步到CN/US服务器中', })
                    } catch (error) {
                        ElMessage({ type: 'warning', message: '请求失败，请重试', })
                    }
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消输入',
                    })
                })
        }
        // 返回响应式数据和函数，使它们在模板中可用  
        return {
            search,
            columns, filterTableData,tableData,
            //handleEdit,
            handleDelete,
            Edit,
            open, ElMessage, ElMessageBox,
            serialNumber,fetchData
        };
    }
};
</script>



<style scoped></style>