<template>

    <el-drawer v-model="localVisible" title="仅供组内交流学习!" :with-header="false" direction="rtl" size="50%"
        :before-close="handleClose">
        <el-text class="mx-1" type="danger">仅供组内交流学习!</el-text>
        <el-table :data="gridData">
            <el-table-column property="func" label="类型" width="60" />
            <el-table-column property="ver" label="起始版本" width="80" />
            <el-table-column property="desc" label="功能描述" />
        </el-table>
    </el-drawer>

</template>


<script>
import { ref, watch } from 'vue';

export default {
    props: {
        modelValue: {
            type: Boolean
        }
    },
    setup(props, { emit }) {
        const gridData = [   
            {
                func: '24',
                ver: 'v50.10',
                desc: '提取特殊功能参数 键盘代码(Keypad Code Programming) - 新增数据需调用爬虫',
            },
            {
                func: '23',
                ver: 'v50.10',
                desc: '根据Vin,确定走在线车型,离线车型,JMC车型',
            },
            {
                func: '22',
                ver: 'v50.10',
                desc: '根据Vin,返回特征码模板 - 配合模版识别车型使用',
            },
            {
                func: '21',
                ver: 'v50.10',
                desc: '下发FDRS故障码(GDS,GGDS,JMC,VW)文本到诊断中,支持多语言',
            },
            {
                func: '20',
                ver: 'v49.90',
                desc: '发送多语言版本提示文本 - 在V50.10版本以及后续版本中停用',
            },
            {
                func: '19',
                ver: 'v50.10',
                desc: '根据VIN下发在线功能文件 -- 包含爬虫,模版,压缩文件,发送邮件,自动化整理deliver和Features等操作',
            },
            {
                func: '18',
                ver: 'v49.90',
                desc: '根据VIN和诊断版本,给2010-2022年非原厂PCM软件号的情况返回匹配的VQ',
            },
            {
                func: '17',
                ver: 'v49.90',
                desc: 'Deliv文件匹配发动机MDX,并返回离线库相似车系ID,在V50.10版本以及后续版本中停用',
            },
            {
                func: '16',
                ver: 'v49.90',
                desc: 'IDS : 离合器电磁阀IDN识别',
            },
            {
                func: '15',
                ver: 'v49.90',
                desc: 'CANFD试探接口',
            },

            {
                func: '12',
                ver: 'v49.90',
                desc: '获取在线车型可编程模块安装数据 - 需配合爬虫处理',
            },
            {
                func: '11',
                ver: 'v49.50',
                desc: '测试客户端网络连通情况',
            },
            {
                func: '10',
                ver: 'v49.70',
                desc: '通过VIN查询Deliver,在V50.10版本以及后续版本中停用',
            },
            {
                func: '7',
                ver: 'v49.60',
                desc: '在线特殊功能配置,在V50.10版本以及后续版本中停用',
            },
            {
                func: '5',
                ver: 'v49.60',
                desc: 'FDRS 16V16 兼容 3V3',
            },
            {
                func: '3',
                ver: 'v49.50',
                desc: 'PATS自动计算，仅适用经典福克斯,老款蒙迪欧',
            },
            {
                func: '2',
                ver: 'v49.50',
                desc: 'Ford 2017年后VIN数据手机,在V50.10版本以及后续版本中停用',
            },
            {
                func: '1',
                ver: 'v49.50',
                desc: 'IDS 16V16',
            },
        ]
//const formLabelWidth = '80px'

        // 创建一个本地响应式变量来控制抽屉的显示状态  
        const localVisible = ref(props.modelValue);

        // 监听 props.visible 的变化，并更新 localVisible  
        watch(() => props.modelValue, (newValue) => {
            localVisible.value = newValue;
        });

        // 处理抽屉关闭事件  
        const handleClose = (done) => {
            // 可以选择添加确认关闭的逻辑  
            emit('update:modelValue', false); // 关闭抽屉，并通知父组件  
            done();
        };

        // 返回给模板的响应式数据和方法  
        return {
            localVisible,
            handleClose,
            gridData
        };
    }
};
</script>

<style scoped>

.el-drawer__header {
    margin-bottom: 0px;
  }
  #el-id-3279-10 {
  padding-top: 0px;
}

</style>

  

